import { memo } from "react";
import Box from "@mui/material/Box";
import { useDiscountBannerContext } from "@/components/DiscountBanner";

const DiscountCodeBannerContent = () => {
    const {
        getPercentageDiscount
    } = useDiscountBannerContext();

    return (
        <Box
            sx={{
                fontFamily: 'inherit',
                color: 'var(--color-white)',
                display: 'grid',
                gap: 2,

                '@media (min-width: 600px)': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6
                }
            }}
        >
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '1rem',
                    lineHeight: '138%',

                    '@media (min-width: 600px)': {
                        fontWeight: 300,
                        fontSize: 32,
                        lineHeight: '108%',
                        textTransform: 'uppercase'
                    }
                }}
            >
                Save Big on your <br />
                <Box
                    sx={{
                        '@media (min-width: 600px)': {
                            fontWeight: 600
                        }
                    }}
                >
                    First Booking
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: .5,
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '138%',

                    '@media (min-width: 600px)': {
                        display: 'grid',
                        transform: 'translateY(20px)'
                    }
                }}
            >
                <Box
                    sx={{
                        '@media (min-width: 600px)': {
                            fontWeight: 300,
                            transform: 'translateY(-10px)'
                        }
                    }}
                >
                    Flat
                </Box>
                <Box
                    sx={{
                        '@media (min-width: 600px)': {
                            fontSize: 64,
                            fontWeight: 600
                        }
                    }}
                >
                    {getPercentageDiscount()}%
                </Box>
                <Box
                    sx={{
                        '@media (min-width: 600px)': {
                            fontSize: 18,
                            fontWeight: 300,
                            transform: 'translateY(10px)'
                        }
                    }}
                >
                    Discount
                </Box>
            </Box>
        </Box>
    );
};

export default memo(DiscountCodeBannerContent);
