import React, { memo, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Components
import Hero from './sections/Hero';
import RecentSearches from './sections/RecentSearches';
import GoNearBy from './sections/GoNearBy';
import Notification from './components/Notification';
import Values from './sections/Values';
import Deals from './sections/Deals';
import Trending from './sections/Trending';
import ExploreStays from './sections/ExploreStays';
import Join from './sections/Join';
import NextTrip from './sections/NextTrip';
import LearnMore from './sections/LearnMore';
import Featured from './sections/Featured';
import InTheMedia from './sections/InTheMedia';
import DownloadApp from './sections/DownloadApp';
import CPBanner from '../Static/care-program/components/CPBanner';
import Subscribe from './sections/Subscribe';
import Crypto from './sections/Crypto';
import Seo from './sections/Seo';
import Footer from '@/components/NewFooter';
// import Header from '@/components/header';
import { Auth } from '@/components/auth';
import Header from '@/components/AppHeader/Header';
import EidBanner from './sections/EidBanner';
import PromiseBanner from '../Static/promise-info/PromiseBanner';
import Discount from './sections/Discount';

const HomePage = ({data}) => {
    //Check Device
    const {isMobile} = useDeviceDetect();
    const location = useSelector(state => state.currentLoc.data);

    const [learnMoreUpdator, setLearnMoreUpdator] = useState(0);
    const updateLearnMode = useCallback(newState => {
      setLearnMoreUpdator(state => state + newState);
    }, []);

    const [isRecentSerchAvailableClass, setRecentSearchAvailableClass] = useState('');
    const onRecentSearchUpdate = useCallback(newState => {
      setRecentSearchAvailableClass(newState ? '' : 'cn__hp__recentsearches--available');
      updateLearnMode(newState);
    }, [updateLearnMode]);

    //---------- Notifications
    const [not1,setNot1] = useState(true);
    //const [not2,setNot2] = useState(true);
    const notification1 = isMobile ? '' : 'Worried about a potential change in plans? Stay flexible and look for properties that allow cancellation until 24 hours before check-in.';
    //const notification2 = isMobile ? 'Keep an eye out for this badge when looking for properties that have additional safety and sanitization measures in effect.' : 'Keep an eye out for this badge when looking for properties that have additional safety and sanitization measures in effect.';
    //---------- Notifications


    //---------- Deals
    const isDeal = data.deals.current_celebrations.length > 0 || data.deals.monthly_discounts.length > 0 || data.deals.weekly_discounts.length > 0;
    //---------- Deals


    //---------- Auth
    //State
    const [showLogIn,setShowLogIn] = useState(false);
    const toggleLogin = (e) => {
        if(showLogIn){
          const popUpInner = document.querySelector('.popup-content-wrapper');
          const popUpOverlay = document.querySelector('.popup-layer');
          popUpInner.style.cssText = window.innerWidth > 600 ? `transform:translateY(-98vh)` : `transform:translateX(100vw)`;
          setTimeout(() => {
            popUpOverlay.style.cssText = `opacity:0`;
            setTimeout(() => {
              document.body.classList.remove('auth-dialog-open');
            }, 300);
          },500);
          setTimeout(() => {
            setShowLogIn(false);
          },850);
        }
    };
    //---------- Auth


    return (
        <div className="cn__homepage">
            <Header />
            {/* <Header isOmnisearch/> */}
            <Hero onChange={updateLearnMode}/>
            {/*------------------  CANCELLATION NOTIFICATION  ------------------*/}
            {/* {not1 && <Notification type="cancellation" tit="Free cancellation on thousands of properties " sub={notification1} action={setNot1}/>} */}
            {/* <EidBanner /> */}
            {/*------------------  RECENT SEARCHES  ------------------*/}
            <RecentSearches onChange={onRecentSearchUpdate} />
            <Discount />
            {/*------------------  DEALS ALL THE WAY  ------------------*/}
            {isDeal && <Deals className={isRecentSerchAvailableClass} d={data.deals}/>}
            {/*------------------  EXPLORE ALL THE DIFFEREN WAYS TO STAY  ------------------*/}
            <ExploreStays propertyTypes={data.propertyTypes} />
            <PromiseBanner />
            {/*------------------  TRENDING DESTINATIONS  ------------------*/}
            <Trending/>
            {/*------------------  GO NEAR BY  ------------------*/}
            <GoNearBy onChange={updateLearnMode}/>
            {/*------------------  DOWNLOAD THE APP  ------------------*/}
            <DownloadApp/>
            {/*------------------  WHAT IS YOUR NEXT TRIP  ------------------*/}
            <NextTrip location={location}/>
            {/*------------------  JOIN CUDDLYNEST  ------------------*/}
            <Join handleLogIn={setShowLogIn}/>
            {/*------------------  VALUES  ------------------*/}
            <Values/>
            {/*------------------  CARE PROGRAM BANNER  ------------------*/}
            <CPBanner/>
            {/*------------------  LEARN MORE  ------------------*/}
            <LearnMore state={learnMoreUpdator}/>
            <Crypto />
            <div className="cn__hp__gradient">
                {/*------------------  FEATURED THIS WEEK  ------------------*/}
                <Featured/>
                {/*------------------  IN THE MEDIA  ------------------*/}
                <InTheMedia/>
                {/*------------------  SUBSCRIBE  ------------------*/}
                <section className="cn__hp__subscribe">
                  <Subscribe />
                </section>
                {/*------------------  SEO  ------------------*/}
                <Seo d={data.seo}/>
                {/*------------------  FOOTER  ------------------*/}
                <Footer location={location} handleLogIn={setShowLogIn}/>
            </div>
            {/*------------------  AUTH LOGIN  ------------------*/}
            {showLogIn && <Auth toggle={toggleLogin}/>}
        </div>
    )
}

export default memo(HomePage);

export * from './context';
