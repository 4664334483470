import { memo } from "react";
import Box from "@mui/material/Box";
import { withExpiration } from "@/components/DiscountBanner";
import DiscountCodeBanner from "../../components/DiscountCodeBanner";

const DiscountCodeBannerComponent = withExpiration(settings => (
    <Box
        className='cn__hp__recentsearches'
    >
        <DiscountCodeBanner
            settings={settings}
        />
    </Box>
));

const Discount = props => (
    <DiscountCodeBannerComponent
        {...props}
    />
);

Discount.defaultProps = {
    expiration: {
        token: 'discountBannerPrompt',
        settings: {
            expires: .5
        }
    }
};

export default memo(Discount);
